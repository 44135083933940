<template>
  <div class="container">
    <br />
    <div class="row">
      <div class="col-md-8">
        <h2>{{ $t("UTILITY.matches") }}</h2>
      </div>
      <div class="col-md-4 text-right">
        <b-btn variant="success" class="light" :to="{ name: 'MatchNew' }">
          {{ $t("MY_MATCHES.send_match") }}
        </b-btn>
      </div>
    </div>

    <!--<b-alert show variant="secondary">
      <h4 class="alert-heading">Minhas Partidas</h4>

      <p
        class="mb-0"
      >Nessa área você consegue assistir suas partidas e as partidas públicas do FIGHTBACK</p>
    </b-alert>-->

    <div class="stats-table table-responsive">
      <table class="table is-hoverable">
        <thead>
          <tr>
            <th>{{ $t("UTILITY.match") }}</th>
            <th>{{ $t("UTILITY.map") }}</th>
            <th>{{ $t("UTILITY.added_at") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="match in matches" :key="match.id">
            <td>
              <div class="match-single">
                <router-link
                  :to="{ name: 'MatchSingle', params: { id: match.id } }"
                  class="forum-link"
                >
                  <div class="team-column" v-if="match.team_a && match.team_b">
                    <div v-if="match.team_a" class="team">
                      {{ match.team_a.name }}
                    </div>
                    x
                    <div v-if="match.team_b" class="team">
                      {{ match.team_b.name }}
                    </div>
                  </div>
                  <p class="score">
                    {{ match.team_a_score }}:{{ match.team_b_score }}
                  </p>
                  <p v-html="match.name || $t('UTILITY.unnamed')"></p>
                </router-link>
              </div>
            </td>

            <td>
              <router-link
                :to="{ name: 'MatchSingle', params: { id: match.id } }"
              >
                <p :class="`${match.map} mapbackground`" v-html="match.map"></p>
              </router-link>
            </td>

            <td>
              {{ match.created_at_friendly }}
              <div
                v-if="match.api_identifier"
                v-b-tooltip.hover
                title="QUEOTA.club Match Code"
              >
                <small>QUEOTA</small>
                {{ match.api_identifier }}
              </div>
              <div
                v-if="match.unique_code"
                v-b-tooltip.hover
                title="FIGHTBACK.club Unique Code"
              >
                <small>FIGHTBACK</small>
                {{ match.unique_code }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination
        size="sm"
        @change="changePage"
        align="center"
        :total-rows="totalRecords"
        v-model="page"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
// import Header from '@/components/Header'
export default {
  name: "MatchList",
  data() {
    return {
      matches: [],
      page: 1,
      perPage: 0,
      totalRecords: 0,
    };
  },
  watch: {
    page: function (newValue) {
      const params = new URLSearchParams(location.search);
      params.set("page", newValue);
      window.history.replaceState({}, "", `${location.pathname}?${params}`);
    },
  },
  methods: {
    initialize() {
      let params = new URLSearchParams(location.search);
      if (params.get("page")) this.page = parseInt(params.get("page"));

      this.loadRecords();
    },
    loadRecords() {
      this.$http.get(`matches.json?page=${this.page}`).then((response) => {
        this.matches = response.data.records;
        this.totalRecords = response.data.total_records;
        this.perPage = response.data.per_page;
      });
    },
    changePage(page) {
      this.page = page;
      this.loadRecords();
    },
  },
  mounted() {
    this.initialize();
  },
  components: {},
};
// Header
</script>

<style lang="sass" scoped>
a:not(.light)
  color: #111
  transition: all .3s
  &:hover
    text-decoration: none
    color: $link
.mapbackground
  height: 40px
  color: white
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  font-size: 13px
  border-radius: 4px
.match-single
  p
    margin: 0
.team-column
  display: flex
  // justify-content: space-around
  div
    font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px
</style>
